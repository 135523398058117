<template>
  <el-collapse style="width: 100% !important; max-width: 1500px; margin-top: 10px;">
    <el-container>
      <el-header>
        <h2>The Parser</h2>
      </el-header>
      <p class="article">   
        This parser allows you to upload .txt, .log, and .zip files, and search for specific strings within them. 
        It will display the lines in an organized manner, with the filename and the matching lines.
        <br><br>Option - You can enter multiple search strings separated by commas
        <br>Option - Prefix a search string with "-" (dash) to exclude lines containing that keyword
      </p>
      <el-main>
        <!-- File Upload -->
        <el-upload
          drag
          multiple
          action=""
          :auto-upload="false"
          :on-change="handleFileUpload"
          :before-upload="beforeUpload"
        >
          <i class="el-icon-upload" />
          <div class="el-upload__text">
            Drag or click to upload .txt, .log, or .zip files
          </div>
        </el-upload>

        <!-- Input for search string -->
        <el-input
          v-model="searchString"
          placeholder="Enter keywords to search, separated by commas"
          class="input-with-select"
        >
          <template #prepend>
            Search Strings:
          </template>
        </el-input>

        <!-- Button to parse files -->
        <el-button
          type="primary"
          @click="parseFiles"
        >
          Parse Files
        </el-button><br>

        <el-label>Search Range:</el-label>
        <!-- Input for search range -->
        <el-input
          v-model.number="searchRange"
          type="number"
          placeholder="Search Range"
          style="width: 100px; margin: 10px;"
        />

        <!-- Checkbox to ignore single lines -->
        <el-checkbox v-model="ignoreSingleLines">
          Ignore single lines
        </el-checkbox>

        <!-- Checkbox for custom CSV export -->
        <el-checkbox v-model="customCsv">
          Custom
        </el-checkbox>

        <!-- Button to download results -->
        <el-button
          v-if="parsedResults.length > 0"
          type="success"
          @click="downloadResults"
        >
          Download Results
        </el-button>

        <!-- Dynamic Textareas for each file with matching lines -->
        <div
          v-for="result in parsedResults"
          :key="result.filename"
          class="result-container"
        >
          <h3>{{ result.filename }}</h3>
          <el-input
            v-model="result.content"
            type="textarea"
            rows="10"
            readonly
            class="small-text"
          />
        </div>
      </el-main>
    </el-container>
  </el-collapse>
</template>

<script>
import JSZip from 'jszip';
import { saveAs } from 'file-saver'; // To enable file downloading

export default {
  data() {
    return {
      files: [],
      searchString: '',
      parsedResults: [],
      parsedText: '', // New data property to hold the combined parsed text
      ignoreSingleLines: false, // Checkbox state
      customCsv: false, // Checkbox state for custom CSV export
      searchRange: 0, // Default search range
    };
  },
  methods: {
    // Handle file upload
    handleFileUpload(file, fileList) {
      console.log('Files uploaded:', fileList);
      this.files = fileList; // Capture all uploaded files
    },

    // Check file types before uploading
    beforeUpload(file) {
      const isTxtLogOrZip = file.type === 'text/plain' || file.type === 'application/zip' || file.name.endsWith('.log');
      if (!isTxtLogOrZip) {
        this.$message.error('Only .txt, .log, and .zip files are supported!');
      }
      return isTxtLogOrZip;
    },

    async parseFiles() {
      console.log('Parsing files with search strings:', this.searchString);
      this.parsedResults = []; // Clear previous results
      this.parsedText = ''; // Clear previous parsed text
      if (!this.searchString) {
        this.$message.error('Please enter search strings.');
        return;
      }

      const searchStrings = this.searchString.split(',').map(str => str.trim());

      for (const file of this.files) {
        console.log('Processing file:', file.raw.name);
        if (file.raw.type === 'application/zip') {
          await this.handleZipFile(file.raw, searchStrings);
        } else if (file.raw.type === 'text/plain' || file.raw.name.endsWith('.log')) {
          await this.parseTextFile(file.raw, searchStrings);
        }
      }

      // Filter out single-line results if the checkbox is checked
      if (this.ignoreSingleLines) {
        this.parsedResults = this.parsedResults.filter(result => {
          const lines = result.content.split('\n');
          return lines.length > 1;
        });
      }

      console.log('Parsed results:', this.parsedResults);
    },

    async handleZipFile(zipFile, searchStrings) {
      const jszip = new JSZip();
      const zip = await jszip.loadAsync(zipFile); // Load the zip file
      await this.processZipEntries(zip, searchStrings); // Process all entries in the zip file
    },

    async processZipEntries(zip, searchStrings) {
      const promises = [];

      zip.forEach((relativePath, zipEntry) => {
        if (!zipEntry.dir) { // If it's not a directory
          if (zipEntry.name.endsWith('.txt') || zipEntry.name.endsWith('.log')) {
            // Handle .txt and .log files within the zip
            const promise = zipEntry.async('string').then((content) => {
              this.processFileContent(zipEntry.name, content, searchStrings);
            });
            promises.push(promise);
          } else if (zipEntry.name.endsWith('.zip')) {
            // Handle nested zip files
            const promise = zipEntry.async('blob').then(async (blob) => {
              const nestedZip = await JSZip.loadAsync(blob);
              await this.processZipEntries(nestedZip, searchStrings); // Recursively process nested zip files
            });
            promises.push(promise);
          }
        }
      });

      await Promise.all(promises); // Wait for all files to be processed
    },

    async parseTextFile(file, searchStrings) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        this.processFileContent(file.name, content, searchStrings);
      };
      reader.readAsText(file);
    },

    processFileContent(filename, content, searchStrings) {
      console.log('Processing file content for:', filename);
      const matchingLines = this.getMatchingLines(content, searchStrings);

      // If there are matching lines, add to results
      if (matchingLines.length > 0) {
        const lines = content.split('\n');
        let resultContent = [];
        let i = 0;

        while (i < lines.length) {
          if (matchingLines.includes(lines[i])) {
            const start = Math.max(0, i - this.searchRange);
            let end = Math.min(lines.length, i + this.searchRange + 1);
            while (end < lines.length && matchingLines.includes(lines[end])) {
              end = Math.min(lines.length, end + this.searchRange + 1);
            }
            resultContent.push(...lines.slice(start, end));
            resultContent.push('-'.repeat(40)); // Add dashes between sections
            i = end; // Skip to the end of the current range
          } else {
            i++;
          }
        }

        const result = {
          filename: filename,
          content: resultContent.join('\n'),
        };
        this.parsedResults.push(result);
        this.parsedText += `File: ${result.filename}\n`;
        this.parsedText += '-'.repeat(40) + '\n';
        this.parsedText += result.content + '\n\n';
      }
    },

    getMatchingLines(content, searchStrings) {
      const lines = content.split('\n'); // Split file content into lines
      const includeStrings = searchStrings.filter(str => !str.startsWith('-'));
      const excludeStrings = searchStrings.filter(str => str.startsWith('-')).map(str => str.substring(1));

      return lines.filter((line) => {
        const includes = includeStrings.some(searchString => line.includes(searchString));
        const excludes = excludeStrings.some(searchString => line.includes(searchString));
        return includes && !excludes;
      });
    },

    downloadResults() {
      if (this.customCsv) {
        this.downloadCustomCsv();
      } else {
        const parsedText = this.parsedResults.map(result => {
          return `File: ${result.filename}\n${'-'.repeat(40)}\n${result.content}\n\n`;
        }).join('');

        const blob = new Blob([parsedText], { type: 'text/plain' });
        saveAs(blob, 'parsed_results.txt');
      }
    },

    downloadCustomCsv() {
      const header = "Tile serial, SFP serial, Site\n";
      const csvContent = this.parsedResults.map(result => {
        const filenamePart = result.filename.match(/__(.*?)\.txt$/) ? result.filename.match(/__(.*?)\.txt$/)[1] : result.filename;
        const lines = result.content.split('\n');
        const sfpSerial = lines[0].match(/B: (.*?):/) ? lines[0].match(/B: (.*?):/)[1] : lines[0];
        const siteValue = lines[1].match(/Current (.*)$/) ? lines[1].match(/Current (.*)$/)[1] : lines[1];
        return `${filenamePart}, ${sfpSerial}, ${siteValue}`;
      }).join('\n');

      const blob = new Blob([header + csvContent], { type: 'text/csv' });
      saveAs(blob, 'parsed_results.csv');
    },
  },
};
</script>

<style>
.result-container {
  margin-bottom: 20px;
}

.small-text .el-input__inner {
  font-size: 4px !important;  /* Adjust the font size as needed */
}
</style>