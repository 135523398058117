<template>
  <div style="margin-top: 100px;">
    <parser />
  </div>
</template>

<script>
import parser from '../components/parser/parser.vue';

export default {
  name: 'Parser',
  components: {
    parser,
  },
  }

</script>
<style lang="scss" scoped>
</style>

